import "babel-polyfill";
import Vue from "vue/dist/vue.js";
import axios from "axios";
import mapFunctions from "./map";
import translation from "./messages";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "cs",
    messages: translation
});

var result_card_similar = require("./result_card_similar.js");

if (document.getElementById("detail_app")) {
    var app = new Vue({
        i18n,
        delimiters: ["[[", "]]"],
        el: "#detail_app",
        data: {
            action: null,
            actions: [],
            actionId: null,
            map_holder_id: "main_map",
            zoom: 12,
            max: 0,
            ready: false,
            center: null,
            m: null,
            allPoints: [],
            isHovered: false
        },
        created() {
            this.actionId = actionId;
            this.getAction();
            this.drawMap = mapFunctions.drawMap;
            this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
            this.addGeometryToMap = mapFunctions.addGeometryToMap;
            this.mapConfig = mapFunctions.mapConfig;
            this.getIcon = mapFunctions.getIcon;
        },
        watch: {
            action: function(val) {
                if (val != null) {
                    this.drawMap(true);
                    this.m.addDefaultLayer(SMap.DEF_TURIST);
                    this.m.addDefaultLayer(SMap.DEF_BASE);
                    var layerSwitch = new SMap.Control.Layer();
                    layerSwitch.addDefaultLayer(SMap.DEF_BASE);
                    layerSwitch.addDefaultLayer(SMap.DEF_OPHOTO);
                    layerSwitch.addDefaultLayer(SMap.DEF_TURIST);
                    this.m.addControl(layerSwitch, { left: "8px", top: "9px" });
                    this.m.addDefaultControls();
                } else {
                    this.ready = false;
                }
            }
        },
        methods: {
            download: function(url) {
                window.open("https://" + window.location.host + "/" + url, "_blank");
            },
            getAction: function() {
                const vm = this;
                axios
                    .get("/api/action/" + actionId)
                    .then(function(response) {
                        vm.action = response.data;
                        vm.getSimilarActions();
                    })
                    .catch(function(e) {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e);
                        }
                    });
            },
            getSimilarActions: function(reload, nowait) {
                const vm = this;

                axios
                    .get(
                        "/api/action/near?lat=" +
                            this.action.lat +
                            "&lon=" +
                            this.action.lon +
                            "&exclude_action_id=" +
                            this.action.id
                    )
                    .then(function(response) {
                        vm.actions = response.data;
                    })
                    .catch(function(e) {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e);
                        }
                        vm.loading = false;
                    });
            }
        }
    });
}
