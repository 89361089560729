import "babel-polyfill";
import Vue from "vue/dist/vue.js";
import axios from "axios";
import translation from "./messages";
import VueI18n from "vue-i18n";
import VTooltip from "v-tooltip";
import mapFunctions from "./map";

Vue.use(VTooltip);
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "cs",
    messages: translation
});

var result_card = require("./result_card.js");

if (document.getElementById("app")) {
    const moment = require("moment");
    require("moment/locale/cs");

    if (!Vue.moment) {
        Vue.use(require("vue-moment"), {
            moment
        });
    }

    var app = new Vue({
        i18n,
        delimiters: ["[[", "]]"],
        el: "#app",
        data: {
            total_pages_count: 0,
            actual_page: 1,
            actions: [],
            limit: 100,
            offset: 0,
            pages: [1, 2, 3],
            loading: false,
            leftTopLat: null,
            leftTopLon: null,
            rightBottomLat: null,
            rightBottomLon: null,
            total_count: 0,
            searchOnMove: false,

            // filters
            filterDialogOpen: false,
            activeFilter: "",

            fulltext: "",
            fulltextModified: false,

            mine: false,
            mineLabel: "Moje",
            mineBadge: null,
            mineClass: "btn-outline-dark",
            mineModified: false,

            hidden: false,
            hiddenLabel: "Skryté akce",
            hiddenBadge: null,
            hiddenClass: "btn-outline-dark",
            hiddenModified: false,

            allCityParts: [],
            cityPart: [],
            cityPartLabel: "Městská část",
            cityPartBadge: null,
            cityPartClass: "btn-outline-dark",
            cityPartModified: false,

            allInvestors: [],
            investor: [],
            investorLabel: "Investor",
            investorBadge: null,
            investorClass: "btn-outline-dark",
            showOtherInvestors: false,
            investorModified: false,

            allTypes: [],
            type: [],
            typeLabel: "Typ akce",
            typeBadge: null,
            typeClass: "btn-outline-dark",
            typeModified: false,

            allStates: [],
            state: [],
            stateLabel: "Stav akce",
            stateBadge: null,
            stateClass: "btn-outline-dark",
            stateModified: false,

            dateBadge: null,
            dateLabel: "Termín akce",
            dateClass: "btn-outline-dark",
            dateFrom: null,
            dateTo: null,
            dateModified: false,

            delayBadge: null,
            delayLabel: "Zdržení",
            delayClass: "btn-outline-dark",
            delay: [],
            delayModified: false,

            exportLabel: "Exportovat",

            // helpers used to handle request
            cancelToken: null,
            requestRunning: false,
            clickTimer: null,

            fullScreen: false,

            bigMap: null,
            bigMapMarkerLayer: null,
            bigMapRedraw: true,
            bigMapMarkers: {},
            bigMapPoints: [],

            complexFilters: false,
            complexFiltersLabel: "Pokročilé filtrování",

            // base filters set as whole dataset
            baseFilters: {}
        },
        created() {
            if (window.location.href.includes("closure=True")) {
                this.closure = true;
            }

            // get all city parts values
            this.allCityParts = [];
            var cityElements = document.getElementsByClassName("cityPart_selector_input");
            for (var i = 0; i < cityElements.length; i++) {
                this.allCityParts.push(cityElements[i].value);
            }

            // get all investors values
            this.allInvestors = [];
            var investorElements = document.getElementsByClassName("investor_selector_input");
            for (var i = 0; i < investorElements.length; i++) {
                this.allInvestors.push(investorElements[i].value);
            }

            // get all types values
            this.allTypes = [];
            var typesElements = document.getElementsByClassName("type_selector_input");
            for (var i = 0; i < typesElements.length; i++) {
                this.allTypes.push(typesElements[i].value);
            }

            // get all states values
            this.allStates = [];
            var stateElements = document.getElementsByClassName("state_selector_input");
            for (var i = 0; i < stateElements.length; i++) {
                this.allStates.push(stateElements[i].value);
                if (stateElements[i].checked == true) {
                    this.state.push(stateElements[i].value);
                }
            }

            // get all states values
            this.allDelays = [];
            var delayElements = document.getElementsByClassName("delay_selector_input");
            for (var i = 0; i < delayElements.length; i++) {
                this.allDelays.push(delayElements[i].value);
            }

            this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
            this.addGeometryToMap = mapFunctions.addGeometryToMap;
            this.mapConfig = mapFunctions.mapConfig;
            this.getIcon = mapFunctions.getIcon;

            // add all data to base filters
            this.baseFilters = { ...this };

            this.getNewData(true, true);
        },
        computed: {
            modifiedFilters: function () {
                return this.dateModified || this.typeModified || this.stateModified
                    || this.mineModified || this.cityPartModified || this.delayModified
                    || this.investorModified || this.fulltextModified || this.hiddenModified;
            }
        },
        watch: {
            offset: function(val) {
                this.updatePager();
            },
            fulltext: function (val) {
                if (val == this.baseFilters.fulltext) {
                    this.fulltextModified = false;
                } else {
                    this.fulltextModified = true;
                }
                this.getNewData(true, false);
            },
            state: function(val) {
                if (val.length > 0) {
                    this.stateClass = "btn-primary";
                    this.stateBadge = val.length;
                } else {
                    this.stateClass = "btn-outline-dark";
                    this.stateBadge = null;
                }
                this.stateModified = !this.arraysEqual(val, this.baseFilters.state);
            },
            delay: function(val) {
                if (val.length > 0) {
                    this.delayClass = "btn-primary";
                    this.delayBadge = val.length;
                } else {
                    this.delayClass = "btn-outline-dark";
                    this.delayBadge = null;
                }
                this.delayModified = !this.arraysEqual(val, this.baseFilters.delay);
            },
            mine: function(val) {
                if (this.mine) {
                    this.mineClass = "btn-primary";
                    this.mineModified = true;
                } else {
                    this.mineClass = "btn-outline-dark";
                    this.mineModified = false;
                }
                this.getNewData(true, false);
            },
            hidden: function(val) {
                if (this.hidden) {
                    this.hiddenClass = "btn-primary";
                    this.hiddenModified = true;
                } else {
                    this.hiddenClass = "btn-outline-dark";
                    this.hiddenModified = false;
                }
                this.getNewData(true, false);
            },
            dateTo: function(val) {
                if (this.dateTo || this.dateFrom) {
                    this.dateClass = "btn-primary";
                    this.mineModified = true;
                } else {
                    this.dateClass = "btn-outline-dark";
                    this.mineModified = false;
                }
            },
            dateFrom: function(val) {
                if (this.dateTo || this.dateFrom) {
                    this.dateClass = "btn-primary";
                    this.mineModified = true;
                } else {
                    this.dateClass = "btn-outline-dark";
                    this.mineModified = false;
                }
            },
            cityPart: function(val) {
                if (val.length > 0 && val.length < 3) {
                    this.cityPartClass = "btn-primary";
                    this.cityPartBadge = null;
                    this.cityPartLabel = "Praha " + val.join(", ");
                } else if (val.length > 2) {
                    this.cityPartClass = "btn-primary";
                    this.cityPartBadge = val.length;
                    this.cityPartLabel = "Městská část";
                } else {
                    this.cityPartClass = "btn-outline-dark";
                    this.cityPartLabel = "Městská část";
                    this.cityPartBadge = null;
                }
                this.cityPartModified = !this.arraysEqual(val, this.baseFilters.cityPart);
            },
            investor: function (val) {
                if (val.length > 0) {
                    this.investorClass = "btn-primary";
                    this.investorBadge = val.length;
                } else {
                    this.investorClass = "btn-outline-dark";
                    this.investorBadge = null;
                }
                this.investorModified = !this.arraysEqual(val, this.baseFilters.investor);
            },
            type: function (val) {
                if (val.length > 0) {
                    this.typeClass = "btn-primary";
                    this.typeBadge = val.length;
                } else {
                    this.typeClass = "btn-outline-dark";
                    this.typeBadge = null;
                }
                this.typeModified = !this.arraysEqual(val, this.baseFilters.type);
            },
            activeFilter: function (val) {
                var element = document.getElementById("navbar_header");
                if (val == "investor" || val == "cityPart" || val == "state" || val == "type"
                    || val == "date" || val == "delay") {
                    element.classList.add("d-none");
                    element.classList.add("d-md-inline");
                    element.classList.remove("d-inline");
                }
                else {
                    element.classList.remove("d-none");
                    element.classList.remove("d-md-inline");
                    element.classList.add("d-inline");
                }
            },
            actions: function(val) {
                this.loading = true;
                this.bigMapMarkers = {};
                if (val != null) {
                    if (this.bigMap == null) {
                        this.drawMap();
                    }

                    this.actions = val;
                    this.addActionsToMap(this.actions);
                }
            },
            fullScreen: function(val) {
                this.redrawMap();
            }
        },
        methods: {
            arraysEqual: function(a, b) {
                if (a === b) return true;
                if (a == null || b == null) return false;
                if (a.length !== b.length) return false;

                // If you don't care about the order of the elements inside
                // the array, you should sort both arrays here.
                // Please note that calling sort on an array will modify that array.
                // you might want to clone your array first.

                for (var i = 0; i < a.length; ++i) {
                  if (a[i] !== b[i]) return false;
                }
                return true;
            },
            getNewData: function(reload, nowait) {
                if (this.filterDialogOpen) {
                    return;
                }
                // mark request as running
                this.requestRunning = true;

                // check whether there is call to server already planned
                if (this.clickTimer) {
                    clearTimeout(this.clickTimer);
                }

                // check whether there is a call to server running
                // if so, cancel the request, new one will be planned instead
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                    this.cancelToken = null;
                }

                if (!nowait) {
                    // plan new call to server in 700ms
                    var self = this;
                    this.clickTimer = setTimeout(function() {
                        self.readDataFromServer(reload);
                    }, 500);
                } else {
                    this.readDataFromServer(reload);
                }
            },
            readDataFromServer: function(reload) {
                this.loading = true;
                const vm = this;

                // cancle already running request
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                }

                // initiate cancel token used to identify, whether ther is a request running already
                this.cancelToken = axios.CancelToken.source();

                if (reload) {
                    this.total_pages_count = 0;
                    this.actual_page = 1;
                    this.offset = 0;
                    this.total_count = 0;
                }
                axios
                    .get(this.buildQuery())
                    .then(function(response) {
                        vm.actions = response.data.objects;
                        vm.total_count = response.data.meta.total_count;
                        vm.total_pages_count = 1;
                        if (response.data.meta.total_count > vm.limit) {
                            vm.total_pages_count =
                                Math.floor(
                                    response.data.meta.total_count / vm.limit
                                ) + 1;
                        }

                        if (
                            response.data.meta.total_count > vm.limit &&
                            response.data.meta.total_count % vm.limit == 0
                        ) {
                            vm.total_pages_count = Math.floor(
                                response.data.meta.total_count / vm.limit
                            );
                        }

                        vm.offset = response.data.meta.offset;

                        vm.updatePager();

                        vm.cancelToken = null;
                        vm.loading = false;
                        vm.scrollToListTop();
                    })
                    .catch(function(e) {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e);
                        }
                        vm.loading = false;
                    });
            },
            nextPage: function() {
                this.offset = this.offset + this.limit;
                this.getNewData(false, true);
            },
            previousPage: function() {
                this.offset = this.offset - this.limit;
                this.getNewData(false, true);
            },
            goToPage: function(val) {
                this.offset = (val - 1) * this.limit;
                console.log("Going to page " + val);
                this.getNewData(false, true);
            },
            buildQuery: function () {
                var query =
                    "/api/action/?limit=" +
                    this.limit +
                    "&offset=" +
                    this.offset;
                query += this.buildFilters();
                return query;
            },
            buildDownloadQuery: function () {
                var query =
                    "/api/action/?"
                query += this.buildFilters().slice(1);
                query += "&format=csv"
                return query;
            },
            buildFilters: function () {
                var query = "";
                if (this.searchOnMove && this.leftTopLon != null) {
                    query += "&lon__gt=" + this.leftTopLon;
                }
                if (this.searchOnMove && this.leftTopLat != null) {
                    query += "&lat__lt=" + this.leftTopLat;
                }
                if (this.searchOnMove && this.rightBottomLon != null) {
                    query += "&lon__lt=" + this.rightBottomLon;
                }
                if (this.searchOnMove && this.rightBottomLat != null) {
                    query += "&lat__gt=" + this.rightBottomLat;
                }
                if (this.fulltext != null) {
                    query += "&fulltext=" + this.fulltext;
                }
                if (this.cityPart != null) {
                    query += "&cityPart=" + this.cityPart;
                }
                if (this.investor != null) {
                    query += "&investor=" + this.investor;
                }
                if (this.type != null) {
                    query += "&type=" + this.type;
                }
                if (this.state != null) {
                    query += "&state=" + this.state;
                }
                if (this.delay != null) {
                    query += "&delay=" + this.delay;
                }

                if (this.dateFrom) {
                    query += "&date_from=" + this.dateFrom;
                }

                if (this.dateTo) {
                    query += "&date_to=" + this.dateTo;
                }

                if (this.mine) {
                    query += "&mine=true";
                }

                if (this.hidden) {
                    query += "&hidden_action=true";
                }

                return query;
            },
            mapPositionUpdated: function(coords) {
                if (this.searchOnMove) {
                    this.leftTopLon = coords[0]["x"];
                    this.leftTopLat = coords[0]["y"];
                    this.rightBottomLon = coords[1]["x"];
                    this.rightBottomLat = coords[1]["y"];
                    this.getNewData(true, false);
                }
            },
            updatePager: function() {
                var page = Math.floor((this.offset + this.limit) / this.limit);

                if (this.total_pages_count > 2) {
                    if (page == 1) {
                        this.pages = [1, 2, 3];
                    }

                    if (page > 2) {
                        this.pages = [page - 1, page, page + 1];
                    }

                    if (page > this.total_pages_count - 1) {
                        this.pages = [
                            this.total_pages_count - 2,
                            this.total_pages_count - 1,
                            this.total_pages_count
                        ];
                    }
                } else {
                    this.pages = [];
                    for (var i = 1; i < this.total_pages_count + 1; i++) {
                        this.pages.push(i);
                    }
                }

                this.actual_page = page;
            },
            activateFilter: function(val) {
                if (val == this.activeFilter) {
                    this.activeFilter = "";
                    this.filterDialogOpen = false;
                    this.getNewData(true, true);
                    document.body.classList.remove("no-scroll");

                } else {
                    this.activeFilter = val;
                    this.filterDialogOpen = true;
                    document.body.classList.add("no-scroll");
                }
            },
            deactivateFilters: function() {
                this.activeFilter = null;
                this.filterDialogOpen = false;
                this.getNewData(true, true);
                document.body.classList.remove("no-scroll");
            },
            resetFilters: function () {
                this.fulltext = this.baseFilters.fulltext;
                this.cityPart = this.baseFilters.cityPart;
                this.investor = this.baseFilters.investor;
                this.leftTopLon = null;
                this.leftTopLat = null;
                this.rightBottomLon = null;
                this.rightBottomLat = null;
                this.state = this.baseFilters.state;
                this.mine = this.baseFilters.mine;
                this.dateTo = this.baseFilters.dateTo;
                this.dateFrom = this.baseFilters.dateFrom;
                this.type = this.baseFilters.type;
                this.delay = this.baseFilters.delay;
                this.hidden = this.baseFilters.hidden;
                this.filterDialogOpen = false;
                this.getNewData(true, true);
            },
            scrollToListTop: function() {
                window.scrollTo(0, 0);
            },
            highlightMarker: function(val) {
                this.$refs.bigMap.highlightMarker(val);
            },
            normaliseMarker: function(val) {
                this.$refs.bigMap.normaliseMarker(val);
            },
            selectAllCityParts: function () {
                this.cityPart = this.allCityParts;
            },
            deselectAllCityParts: function () {
                this.cityPart = [];
            },
            selectAllInvestors: function () {
                this.investor = this.allInvestors;
            },
            deselectAllInvestors: function () {
                this.investor = [];
            },
            deselectDates: function () {
                this.dateFrom = null;
                this.dateTo = null;
            },
            selectAllTypes: function () {
                this.type = this.allTypes;
            },
            deselectTypes: function () {
                this.type = [];
            },
            selectAllStates: function () {
                this.state = this.allStates;
            },
            deselectStates: function () {
                this.state = [];
            },
            selectAllDelays: function () {
                this.delay = this.allDelays;
            },
            deselectDelays: function () {
                this.delay = [];
            },
            showOtherInvestorsToggle: function () {
                this.showOtherInvestors = !this.showOtherInvestors;
            },
            drawMap: function() {
                var center = SMap.Coords.fromWGS84(
                    14.438710255616163,
                    50.074427024615723
                );
                this.bigMap = new SMap(JAK.gel("big_map_holder"), center, 11);

                this.bigMap.addDefaultLayer(SMap.DEF_SMART_BASE).enable();

                var sync = new SMap.Control.Sync();
                this.bigMap.addControl(sync);

                this.bigMap.addDefaultControls();

                const vm = this;
                var listener = function(e) {
                    if (!vm.loading) {
                        if (
                            e.type == "control-mouse-move" ||
                            e.type == "zoom-stop"
                        ) {
                            var coords = vm.getMapBorder();
                            if (vm.searchOnMove) {
                                vm.bigMapRedraw = false;
                            }

                            this.mapPositionUpdated(coords);
                        }
                    }
                };

                var signals = this.bigMap.getSignals();
                signals.addListener(window, "*", listener);
            },
            addActionsToMap: function() {
                this.loading = true;
                if (this.bigMapMarkerLayer == null) {
                    this.bigMapMarkerLayer = new SMap.Layer.Marker();
                    //var clusterer = new SMap.Marker.Clusterer(this.m);
                    //this.bigMapMarkerLayer.setClusterer(clusterer);
                    this.bigMap.addLayer(this.bigMapMarkerLayer);
                    this.bigMapMarkerLayer.enable();
                } else {
                    this.bigMapMarkerLayer.removeAll();
                }

                this.bigMapPoints = [];
                for (var action of this.actions) {
                    var icon = this.getIcon(action, false);

                    var center = SMap.Coords.fromWGS84(action.lon, action.lat);
                    var marker = new SMap.Marker(center, action.id, { url: icon });
                    this.bigMapMarkers[action.id] = {
                        marker: marker,
                        action: action
                    };

                    var card = new SMap.Card();
                    var html = `
                        <div class="card_body_inner card_body_inner_%severity">
                        <h3>
                            Praha %city_part
                        </h3>
                        <h2>%name</h2>
                        <p class="detail_link">
                            <a href="/action/%id" target="_blank">detail</a>
                        </p>
                        <img class="severity_icon" src="/static/v1/img/icon_cover.png" />
                        </div>
                    `
                        .replace("%id", action.id)
                        .replace("%city_part", action.city_part.join(", "))
                        .replace("%severity", action.severity)
                        .replace("%name", action.name);
                    card.getBody().innerHTML = html;
                    marker.decorate(SMap.Marker.Feature.Card, card);

                    this.bigMapPoints.push(center);
                    this.bigMapMarkerLayer.addMarker(marker);
                }

                this.bigMapMarkerLayer.enable();
                if (this.bigMapRedraw) {
                    this.redrawMap();
                }

                this.loading = false;
                this.bigMapRedraw = true;
            },
            getMapBorder: function getMapBorder() {
                var size = this.bigMap.getSize();
                var w = Math.round(size.x / 2);
                var h = Math.round(size.y / 2);
                var leftTop = new SMap.Pixel(-w, -h);
                var leftTopCoords = leftTop.toCoords(this.bigMap);
                var leftTopWGS = leftTopCoords.toWGS84();
                var botRight = new SMap.Pixel(w, h);
                var botRightCoords = botRight.toCoords(this.bigMap);
                var botRightWGS = botRightCoords.toWGS84();
                return [leftTopCoords, botRightCoords];
            },
            highlightMarker: function(val) {
                if (val != null) {
                    var marker = this.bigMapMarkers[val].marker;
                    //this.bigMapMarkerLayer.removeMarker(marker);

                    var icon = this.getIcon(this.bigMapMarkers[val].action, true);

                    //var marker = new SMap.Marker(marker.getCoords(), val, {
                    //    url: icon
                    //});
                    //this.bigMapMarkerLayer.addMarker(marker);
                    marker.setURL(icon);
                    this.bigMapMarkerLayer.redraw();
                }
            },
            normaliseMarker: function(val) {
                if (val != null) {
                    var marker = this.bigMapMarkers[val].marker;
                    //this.bigMapMarkerLayer.removeMarker(marker);

                    var icon = this.getIcon(this.bigMapMarkers[val].action, false);

                    //var marker = new SMap.Marker(marker.getCoords(), val, {
                    //    url: icon
                    //});
                    //this.bigMapMarkerLayer.addMarker(marker);

                    marker.setURL(icon);
                    this.bigMapMarkerLayer.redraw();
                }
            },
            redrawMap: function(val) {
                if (this.bigMapPoints.length > 0) {
                    var center_zoom = this.bigMap.computeCenterZoom(this.bigMapPoints);
                    this.bigMap.setCenterZoom(center_zoom[0], center_zoom[1]);
                } else {
                    var center = SMap.Coords.fromWGS84(
                        14.438710255616163,
                        50.074427024615723
                    );
                    this.bigMap.setCenterZoom(center, 11);
                }
            },
            exportData: function () {
                window.open(this.buildDownloadQuery(), '_blank');
                // axios
                //     .get(this.buildDownloadQuery())
                //     .then(function (response) {

                //         var blobObj = new Blob([response.data], { type: response.headers["content-type"] });
                //         const href = URL.createObjectURL(blobObj);

                //         // create "a" HTML element with href to file & click
                //         const link = document.createElement('a');
                //         link.href = href;
                //         //create default name
                //         link.setAttribute('download', 'export.csv');
                //         document.body.appendChild(link);
                //         link.click();

                //         // clean up "a" element & remove ObjectURL
                //         document.body.removeChild(link);
                //         URL.revokeObjectURL(href);
                //     })
            },
            activeComplexFilters: function () {
                this.complexFilters = true;
            }
        }
    });
}
