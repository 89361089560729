export default {
    mapConfig: {
        GREEN: {
            color: "#7ED321",
            icon: "/static/v1/img/icon_GREEN.png",
            iconHovered: "/static/v1/img/icon_hovered_GREEN.png"
        },
        YELLOW: {
            color: "#ec7c32",
            icon: "/static/v1/img/icon_YELLOW.png",
            iconHovered: "/static/v1/img/icon_hovered_YELLOW.png"
        },
        RED: {
            color: "#D0021B",
            icon: "/static/v1/img/icon_RED.png",
            iconHovered: "/static/v1/img/icon_hovered_RED.png"
        }
    },
    drawMap: function(addGeometry) {
        // init map
        this.m = new SMap(JAK.gel(this.map_holder_id), this.center, this.zoom);

        if (addGeometry) {
            // draw polygons to the layer
            this.addGeometryToMap();
        }

        // sets proper zoom level and center of the map
        this.setZoomAndCenter();

        // add photo layer
        this.m.addDefaultLayer(SMap.DEF_OPHOTO).enable();
    },
    setZoomAndCenter: function() {
        var center_zoom = this.m.computeCenterZoom(this.allPoints);
        this.m.setCenterZoom(center_zoom[0], center_zoom[1]);
    },
    addGeometryToMap: function() {
        var geo_json = JSON.parse(this.action.geo_json);
        var geo_features = geo_json.features;

        var polyLayer = new SMap.Layer.Geometry();
        var markerLayer = new SMap.Layer.Marker();

        this.allPoints = [];

        var color = this.mapConfig[this.action.severity].color;

        for (var feature of geo_features) {
            if (feature.geometry.type == "MultiPolygon") {
                var options = {
                    color: color,
                    outlineColor: color
                };

                for (var poly of feature.geometry.coordinates) {
                    var points = [];
                    for (var coord of poly) {
                        for (var point of coord) {
                            points.push(
                                SMap.Coords.fromWGS84(point[0], point[1])
                            );
                            this.allPoints.push(
                                SMap.Coords.fromWGS84(point[0], point[1])
                            );
                        }
                    }

                    var polygon = new SMap.Geometry(
                        SMap.GEOMETRY_POLYGON,
                        null,
                        points,
                        options
                    );
                    polyLayer.addGeometry(polygon);
                }
            }

            if (feature.geometry.type == "Polygon") {
                var options = {
                    color: color,
                    outlineColor: color
                };
                var points = [];
                for (var coord of feature.geometry.coordinates) {
                    for (var point of coord) {
                        points.push(SMap.Coords.fromWGS84(point[0], point[1]));
                        this.allPoints.push(
                            SMap.Coords.fromWGS84(point[0], point[1])
                        );
                    }
                }

                var polygon = new SMap.Geometry(
                    SMap.GEOMETRY_POLYGON,
                    null,
                    points,
                    options
                );
                polyLayer.addGeometry(polygon);
            }

            if (feature.geometry.type == "LineString") {
                var options = {
                    color: color,
                    width: 4,
                    outlineWidth: 0
                };

                var points = [];
                for (var point of feature.geometry.coordinates) {
                    points.push(SMap.Coords.fromWGS84(point[0], point[1]));
                    this.allPoints.push(
                        SMap.Coords.fromWGS84(point[0], point[1])
                    );
                }

                var polyline = new SMap.Geometry(
                    SMap.GEOMETRY_POLYLINE,
                    null,
                    points,
                    options
                );
                polyLayer.addGeometry(polyline);
            }

            if (feature.geometry.type == "MultiLineString") {
                var options = {
                    color: color,
                    width: 4,
                    outlineWidth: 0
                };

                for (var line of feature.geometry.coordinates) {
                    var points = [];
                    for (var point of line) {
                        points.push(SMap.Coords.fromWGS84(point[0], point[1]));
                        this.allPoints.push(
                            SMap.Coords.fromWGS84(point[0], point[1])
                        );
                    }

                    var polyline = new SMap.Geometry(
                        SMap.GEOMETRY_POLYLINE,
                        null,
                        points,
                        options
                    );
                    polyLayer.addGeometry(polyline);
                }
            }

            if (feature.geometry.type == "Point") {
                var icon = this.getIcon(this.action, false);

                point = SMap.Coords.fromWGS84(
                    feature.geometry.coordinates[0],
                    feature.geometry.coordinates[1]
                );
                this.allPoints.push(
                    SMap.Coords.fromWGS84(
                        feature.geometry.coordinates[0],
                        feature.geometry.coordinates[1]
                    )
                );
                var marker = new SMap.Marker(point, Math.random(), {
                    url: icon
                });
                markerLayer.addMarker(marker);
            }
        }

        this.m.addLayer(polyLayer);
        this.m.addLayer(markerLayer);

        // enable polygon layer
        polyLayer.enable();
        markerLayer.enable();
    },
    getPoints: function(geoJson) {
        var geo_json = JSON.parse(geoJson);
        var geo_features = geo_json.features;

        var allPoints = [];

        for (var feature of geo_features) {
            if (feature.geometry.type == "MultiPolygon") {
                for (var poly of feature.geometry.coordinates) {
                    for (var coord of poly) {
                        for (var point of coord) {
                            allPoints.push(
                                SMap.Coords.fromWGS84(point[0], point[1])
                            );
                        }
                    }
                }
            }

            if (feature.geometry.type == "Polygon") {
                for (var coord of feature.geometry.coordinates) {
                    for (var point of coord) {
                        allPoints.push(
                            SMap.Coords.fromWGS84(point[0], point[1])
                        );
                    }
                }
            }

            if (feature.geometry.type == "LineString") {
                for (var point of feature.geometry.coordinates) {
                    allPoints.push(
                        SMap.Coords.fromWGS84(point[0], point[1])
                    );
                }
            }

            if (feature.geometry.type == "MultiLineString") {
                for (var line of feature.geometry.coordinates) {
                    for (var point of line) {
                        allPoints.push(
                            SMap.Coords.fromWGS84(point[0], point[1])
                        );
                    }
                }
            }

            if (feature.geometry.type == "Point") {
                this.allPoints.push(
                    SMap.Coords.fromWGS84(
                        feature.geometry.coordinates[0],
                        feature.geometry.coordinates[1]
                    )
                );
            }
        }

        return allPoints;
    },
    getIcon: function(action, hovered) {
        var icon = JAK.mel("div");

        if (action.severity != null) {
            if (!hovered) {
                var image = JAK.mel("img", {
                    src: this.mapConfig[action.severity].icon
                });
            } else {
                var image = JAK.mel("img", {
                    src: this.mapConfig[action.severity].iconHovered
                });
            }
        } else {
            var image = JAK.mel("img", {
                src: this.mapConfig["GREEN"].iconHovered
            });
        }

        icon.appendChild(image);
        return icon;
    }
};
