import 'babel-polyfill'
import Vue from 'vue/dist/vue.js'
import translation from './messages'
import VueI18n from 'vue-i18n'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'cs',
    messages: translation,
})

if(document.getElementById("footer")) {
    var app = new Vue({
        i18n,
        delimiters: ['[[', ']]'],
        el: '#footer',
    })
}