import Vue from 'vue/dist/vue.js'
import mapFunctions from './map'

Vue.component("result-card-similar", {
    delimiters: ['[[', ']]'],
    template: '#result-card-similar',
    props: ['id', 'action'],
    data: function() {
        return {
            map_holder_id: null,
            zoom: 12,
            max: 0,
            ready: false,
            center: null,
            m: null,
            allPoints: [],
            isHovered: false,
            subjectSpecificClass: null,
            mapConfig: null,
        };
    },
    created: function () {
        this.drawMap = mapFunctions.drawMap;
        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.drawMap = mapFunctions.drawMap;
        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.mapConfig = mapFunctions.mapConfig;
        this.getIcon = mapFunctions.getIcon;
        this.map_holder_id = "result_card_map_holder_" + this.id;
    },
    watch: {
        action: function (val) {
            if (val != null) {
                this.action = val;
                this.subjectSpecificClass = "result_card_" + val.subject;
                this.ready = true,
                this.drawMap(true);
            } else {
                this.ready = false;
                this.m.$destructor();
            }
        },
    },
    methods: {
        hovered: function () {
            this.isHovered = true;
            this.$emit('hovered', this.action.id);
        },
        unhovered: function () {
            this.isHovered = false;
            this.$emit('unhovered', this.action.id);
        },
        openDetail: function () {
            window.open("/action/" + this.action.id, '_blank');
        },
    }
})