import "babel-polyfill";
import Vue from "vue/dist/vue.js";
import axios from "axios";
import mapFunctions from "./map";
import translation from "./messages";
import VueI18n from "vue-i18n";
import Datepicker from "vuejs-datepicker";
import { en, cs } from "vuejs-datepicker/dist/locale";
import VueMoment from "vue-moment";
import { VueEditor } from "vue2-editor";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

Settings.defaultLocale = "cs";
Vue.component("datepicker", Datepicker);
Vue.component("vue-editor", VueEditor);
Vue.component("datetime", Datetime);
Vue.use(Datetime);
Vue.use(VueI18n);
if (!Vue.moment) {
    Vue.use(VueMoment);
}

const i18n = new VueI18n({
    locale: "cs",
    messages: translation
});

if (document.getElementById("edit_app")) {
    var app = new Vue({
        i18n,
        delimiters: ["[[", "]]"],
        el: "#edit_app",
        data() {
            return {
                en: en,
                cs: cs,
                datepickerClass: "form-control",
                action: null,
                actionId: null,
                map_holder_id: "edit_map",
                zoom: 12,
                max: 0,
                ready: false,
                center: null,
                m: null,
                allPoints: [],
                isHovered: false,
                lat: null,
                lon: null,
                geoJson: null,
                marker: null,
                originalLat: null,
                originalLon: null,
                description: null,
                limitations: null,
                investor: null,
                detours: null,
                customToolbar: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ align: [] }],
                    ["link"]
                ],
                actionStart: null,
                actionStartTime: null,
                actionEnd: null,
                actionEndTime: null,
                valid: true,
                geoJsonValid: true
            };
        },
        created() {
            var investor = document.getElementById("action_investor").value;
            if (investor && investor != "Ostatní") {
                this.investor = investor;
            }

            this.actionId = actionId;
            this.description = description;
            this.limitations = limitations;
            this.detours = detours;
            this.actionStart = actionStart;
            this.actionStartTime = actionStartTime;
            this.actionEnd = actionEnd;
            this.actionEndTime = actionEndTime;
            this.geoJson = geoJson;
            this.getAction();
            this.drawMap = mapFunctions.drawMap;
            this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
            this.addGeometryToMap = mapFunctions.addGeometryToMap;
            this.mapConfig = mapFunctions.mapConfig;
            this.getIcon = mapFunctions.getIcon;
            this.getPoints = mapFunctions.getPoints;
            this.lat = actionLat;
            this.lon = actionLon;
        },
        watch: {
            action: function(val) {
                if (val != null) {
                    this.drawMap(false);
                    this.m.addDefaultLayer(SMap.DEF_TURIST);
                    this.m.addDefaultLayer(SMap.DEF_BASE);
                    var layerSwitch = new SMap.Control.Layer();
                    layerSwitch.addDefaultLayer(SMap.DEF_BASE);
                    layerSwitch.addDefaultLayer(SMap.DEF_OPHOTO);
                    layerSwitch.addDefaultLayer(SMap.DEF_TURIST);
                    this.m.addControl(layerSwitch, { left: "8px", top: "9px" });
                    this.m.addDefaultControls();
                    this.addActionToMap();

                    const vm = this;
                    var listener = function(e) {
                        var coords = e.target.getCoords();
                        vm.lon = coords.x;
                        vm.lat = coords.y;
                    };

                    var listenerClick = function(e, elm) {
                        var coords = SMap.Coords.fromEvent(e.data.event, vm.m);
                        vm.lon = coords.x;
                        vm.lat = coords.y;
                        vm.marker.setCoords(coords);
                    };
                    var signals = this.m.getSignals();
                    signals.addListener(window, "marker-drag-stop", listener);
                    signals.addListener(window, "map-click", listenerClick);
                } else {
                    this.ready = false;
                }
            },
            geoJson: function (val) {
                try {
                    JSON.parse(val);
                    this.valid = true;
                    this.geoJsonValid = true;
                } catch (e) {
                    this.valid = false;
                    this.geoJsonValid = false;
                }
            }
        },
        computed: {
            otherInvestor: function () {
                return !this.investor;
            }
        },
        methods: {
            customFormatter(date) {
                return this.$moment(date).format("DD. MM. YYYY");
            },
            getAction: function() {
                if (actionId != "") {
                    const vm = this;
                    axios
                        .get("/api/action/" + actionId)
                        .then(function(response) {
                            vm.action = response.data;
                        })
                        .catch(function(e) {
                            if (axios.isCancel(e)) {
                                console.log("Request canceled", e.message);
                            } else {
                                console.log("Actions retrieval failed");
                                console.log(e);
                            }
                        });
                } else {
                    this.action = {};
                }
            },
            addActionToMap: function() {
                this.markerLayer = new SMap.Layer.Marker();
                this.m.addLayer(this.markerLayer);
                this.markerLayer.enable();

                this.originalLat = this.action.lat;
                this.originalLon = this.action.lon;

                var icon = this.getIcon(this.action, false);

                if (this.action.lon == null) {
                    this.action.lon = 14.42076;
                    this.action.lat = 50.08804;
                }

                var center = SMap.Coords.fromWGS84(
                    this.action.lon,
                    this.action.lat
                );
                this.marker = new SMap.Marker(center, this.action.id, {
                    url: icon
                });
                this.marker.decorate(SMap.Marker.Feature.Draggable);

                this.markerLayer.addMarker(this.marker);

                this.markerLayer.enable();

                var points = [];
                points.push(center);

                var center_zoom = this.m.computeCenterZoom(points);
                this.m.setCenterZoom(center_zoom[0], center_zoom[1]);

                var node = this.marker.getContainer();
                node[SMap.LAYER_MARKER].style.cursor = "grab";
            },
            resetActionPosition: function() {
                this.marker.setCoords(
                    SMap.Coords.fromWGS84(this.originalLon, this.originalLat)
                );

                var points = [];
                points.push(
                    SMap.Coords.fromWGS84(this.originalLon, this.originalLat)
                );

                var center_zoom = this.m.computeCenterZoom(points);
                this.m.setCenterZoom(center_zoom[0], center_zoom[1]);

                var node = this.marker.getContainer();
                node[SMap.LAYER_MARKER].style.cursor = "grab";

                this.lat = this.originalLat;
                this.lon = this.originalLon;
            },
            centerActionPosition: function() {
                var center_zoom = this.m.computeCenterZoom(this.getPoints(this.geoJson));
                this.marker.setCoords(
                    SMap.Coords.fromWGS84(center_zoom[0].x, center_zoom[0].y)
                );

                this.m.setCenterZoom(center_zoom[0], 19);

                var node = this.marker.getContainer();
                node[SMap.LAYER_MARKER].style.cursor = "hand";

                this.lon = center_zoom[0].x;
                this.lat = center_zoom[0].y;
            }
        }
    });
}
