const messages = {
    cs: {
        severity: {
            RED: "Zdržení více než 30 minut",
            YELLOW: "Zdržení 10-30 minut",
            GREEN: "Zdržení do 10 minut"
        }
    }
};

export default messages;
