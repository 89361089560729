import 'babel-polyfill'
import Vue from 'vue/dist/vue.js'
import axios from 'axios';
import mapFunctions from './map'
import translation from './messages'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'cs',
    messages: translation,
})

var result_card_similar = require('./result_card_similar.js')

if (document.getElementById("index_app")) {
    var app = new Vue({
        i18n,
        delimiters: ['[[', ']]'],
        el: '#index_app',
        data: {
            nearActions: [],
            closureActions: [],
            lat: 14.438710255616163,
            lon: 50.074427024615723
        },
        created() {
            this.drawMap = mapFunctions.drawMap;
            this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
            this.addGeometryToMap = mapFunctions.addGeometryToMap;
            this.mapConfig = mapFunctions.mapConfig;
            this.getIcon = mapFunctions.getIcon;
            this.getClosureActions();
            //this.getNearActions();
        },
        mounted() {
            console.log("Getting position");
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                        this.lat = position.coords.latitude;
                        this.lon = position.coords.longitude;
                        console.log("Position acquired lat:" + position.coords.latitude + " lon: " + position.coords.longitude);
                        this.getClosureActions();
                        this.getNearActions();
                    },
                    function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                console.log("User denied the request for Geolocation.");
                                break;
                            case error.POSITION_UNAVAILABLE:
                                console.log("Location information is unavailable.");
                                break;
                            case error.TIMEOUT:
                                console.log("The request to get user location timed out.");
                                break;
                            case error.UNKNOWN_ERROR:
                                console.log("An unknown error occurred.");
                                break;
                        }
                    })
            }
        },
        methods: {
            getNearActions: function () {
                const vm = this;

                axios.get("/api/action/near?lat=" + this.lat + "&lon=" + this.lon)
                    .then(function (response) {
                        vm.nearActions = response.data;
                    })
                    .catch(function (e) {
                        if (axios.isCancel(e)) {
                            console.log('Request canceled', e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e)
                        }
                        vm.loading = false;
                    });
            },
            getClosureActions: function () {
                const vm = this;
                axios.get("/api/action/near?lat=50.06268655&lon=14.5042502&closure=True")
                    .then(function (response) {
                        vm.closureActions = response.data;
                    })
                    .catch(function (e) {
                        if (axios.isCancel(e)) {
                            console.log('Request canceled', e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e)
                        }
                        vm.loading = false;
                    });
            },
        }
    })
}